
@font-face {
	font-family: 'Bell MT';
	src: url('../assets/fonts/BellMTBold.woff2') format('woff2'),
		url('../assets/fonts/BellMTBold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'ArialMT';
	src: url('../assets/fonts/ArialMT-Regular.woff2') format('woff2'), url('../assets/fonts/ArialMT-Regular.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Bell MT';
	src: url('../assets/fonts/BellMTItalic.woff2') format('woff2'),
		url('../assets/fonts/BellMTItalic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Arial MT';
	src: url('../assets/fonts/ArialMT-Bold.woff2') format('woff2'),
		url('../assets/fonts/ArialMT-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
    font-family: 'Arial GEO';
    src: url('../assets/fonts/ArialGEOBold.woff2') format('woff2'),
        url('../assets/fonts/ArialGEOBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}



@font-face {
	font-family: 'Arial MT';
	src: url('../assets/fonts/ArialMTStd-Light.woff2') format('woff2'), url('../assets/fonts/ArialMTStd-Light.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

